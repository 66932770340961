import {useState} from "react"
import Modal from 'react-modal'
import styles from "./modal.module.scss"
import Button from "../button";

export default function ({
                             children,
                             label,
                             showModal,
                             setShowModal = () => {},
                             callback = () => {},
                             reject = () => {},
                             okText
}) {

    return <Modal
        isOpen={showModal}
        contentLabel={label}
        onRequestClose={e => {
            e.stopPropagation();
            setShowModal(false)
        }}
        className={styles.Modal}
        overlayClassName={styles.Overlay}
        ariaHideApp={false}
    >
        {children}
        <div className={styles.buttons}>
            <Button className={styles.button} onClick={callback}>
                {okText}
            </Button>
            <Button className={styles.button} isWhite onClick={e => {
                e.stopPropagation()
                reject()
                setShowModal(false)
            }}>
                Отмена
            </Button>
        </div>
    </Modal>
}