import {initializeApp} from "firebase/app";
import {getToken, onMessage} from "firebase/messaging";
import { getMessaging } from "firebase/messaging";
import { isSupported } from "firebase/messaging";
import { useEffect, useState } from "react";
import { isYaBrowser } from "../utils/functions";
import { onBackgroundMessage } from "firebase/messaging/sw";

const firebaseConfig = {
    apiKey: "AIzaSyCgWMLYvG6AkCJt-G2hFK5U0nE5FT6uSXU",
    authDomain: "fllauncher-52e2a.firebaseapp.com",
    projectId: "fllauncher-52e2a",
    storageBucket: "fllauncher-52e2a.appspot.com",
    messagingSenderId: "146707316934",
    appId: "1:146707316934:web:96ff4fd7a1a535a51c97c7",
    serviceWorkerURL: "/firebase-messaging-sw.js",
};
const vapidKey = 'BHvNGtvRNjt94eDBDMYBsU670dMJQSWCWyEOYi4mYaMyLKxMv9Iu1-meeWmlUAGQHiRC52gJH1Fh-R_MvlZnfWs'

export default function Firebase ({children, setToken}) {
    const [notificationsSupported, setIsSupported] = useState(false)
    const [inizialized, setInizialized] = useState(false)
    const [app, setApp] = useState()
    const [messaging, setMessaging] = useState()
    const storedToken = localStorage.getItem("fb-token");    

    useEffect (() => {
        if (!inizialized) {
            isSupported().then(e => {
                console.log("PUSH API isSupported", e)
                setInizialized(true)
                setIsSupported(e)
                const init = initializeApp(firebaseConfig)
                setApp(init)
                setMessaging(getMessaging(init))
            }).catch(e => {
                setInizialized(true)
                console.error(e);
            })
        } else {
            navigator.serviceWorker.onmessage = (props) => {
                console.log(props)
                // if (data.messageType === "push-received") onMessage(data);
              };
        }
    }, [inizialized])
    

    useEffect (() => {
        if (inizialized && !notificationsSupported) {
            console.error("notifications is not supported by browser!");
        }
    }, [inizialized, notificationsSupported])

    useEffect(() => {
        if (!storedToken && notificationsSupported && messaging) {
            getToken(messaging, { vapidKey }).then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    // ...
                    console.log("FB token:", currentToken)
                    setToken(currentToken)
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // ...
            });
        
        }
    }, [storedToken, notificationsSupported, messaging])

    return <>{children}</>
}