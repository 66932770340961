import classNames from "classnames"
import styles from "./application-item.module.scss"
import Modal from "../modal"
import {useState} from "react";

export default function ({app, isPoor}) {
  const [showModal, setShowModal] = useState();

  return <div
      className={classNames(styles.item, {[styles.borderTop]: isPoor})}
      onClick={e => {
        e.stopPropagation()
        setShowModal(true);
      }}
    >
    <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        callback={e => {
          e.stopPropagation()
          window.open(app.link)
          setTimeout(() => {
              setShowModal(false)
          }, 500)
        }}
        okText={"Открыть"}
    >
        <div className={styles.modalBody}>
            <img src={app.iconLink} className={styles.icon} alt="" />
            <div className={styles.title}>{app.title}</div>
            <div className={styles.description}>{app.description}</div>
        </div>
    </Modal>
    <img src={app.iconLink} className={styles.icon} alt="" />
    <div className={styles.content}>
      <div className={styles.title}>{app.title}</div>
      <div className={styles.description}>{app.description}</div>
    </div>
  </div>
}