import Button from "../../components/button";
import styles  from "./login.module.scss";

export default function Login ({keycloak}) {

  return <div className={styles.wrapper}>
  <div className={styles.container}>
  <div className={styles.title}>Войти в familia launcher</div>
  <Button className={styles.button} isWhite onClick={() => keycloak.login()}>Войти</Button>
  </div>
</div>
}
