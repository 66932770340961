import classNames from "classnames";
import styles from "./button.module.scss";

export default function Button (props) {
  if (!props) {
    return <button></button>
  }
  const {children, className, isWhite} = props;

  return <button {...props} className={classNames(styles.button, {[styles.isWhite]: isWhite}, className)}>{children}</button>
}