import styles from "./application.module.scss"
import icon from "../../media/search-24x24-greysvg.svg"
import classNames from "classnames"

export default function ({search, setSearch}) {
    return <div className={styles.container}>
        <img src={icon} alt=""  className={styles.icon}/>
        <input type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder="Поиск"/>
        <div onClick={() => setSearch("")} className={classNames(styles.clearBtn, {[styles.hidden]: search.length === 0})}>Показать все приложения</div>
    </div>
}