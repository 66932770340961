import { useEffect, useState} from 'react';
import axios from 'axios';
import keycloak from '../../utils/Keycloak'
import { getBaseUrl } from '../../utils/url';

export const instance = axios.create({
  baseURL: `${getBaseUrl()}/api/v1`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': `Bearer ${keycloak.token}`
  }
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Токен протух
      if (err.response.status === 401 && !originalConfig.sent) {//TODO:  Разобраться с настройками keycloak чтобы отдавал 401  вместо 403
        originalConfig.sent  = true;

        try {
          const rs = await refreshToken();
          console.log(rs)
          const { access_token, refresh_token } = rs.data;

          keycloak.updateToken({access_token, refresh_token})
          originalConfig.headers = {
            ...originalConfig.headers,
            Authorization: `Bearer ${access_token}`,
          };
          localStorage.setItem('kc_token', access_token);
          localStorage.setItem('kc_refreshToken', refresh_token);
          // instance.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;

          return instance(originalConfig);
        } catch (_error) {

          if (_error.response && _error.response.data) {
            if (_error.response.data.error === "invalid_grant") {
              //если рефреш токен плохой, делаем логаут
              localStorage.removeItem('kc_token');
              localStorage.removeItem('kc_refreshToken');
              keycloak.logout()
            }
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      // if (err.response.status === 403 && err.response.data) {
      //   return Promise.reject(err.response.data);
      // }
    }

    return Promise.reject(err);
  }
);

function refreshToken() {
  return axios.post(`${process.env.REACT_APP_KC_HOST}/realms/${process.env.REACT_APP_KC_REALM}/protocol/openid-connect/token`, {
    refresh_token: keycloak.refreshToken,
    client_id: process.env.REACT_APP_KC_CLIENT_ID,
    grant_type: "refresh_token"
  }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${keycloak.token}`
    }
  });
}


const useFetchApps = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await instance.get(`/user/applications`);
        setData(response);
      } catch (error) {
        console.error(error)
      }
      setLoading(false);
    };

    if (!loading) {
      setLoading(true)
      fetchData();
    } 
  }, []);

  return {
    data,
    loading,
  };
};

export default useFetchApps;