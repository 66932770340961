import keycloak from './utils/Keycloak'
import Layout from './components/layout/layout';
import Applications from './features/applications';
import Login from './features/login';
import Loader from './components/loader';
import { useEffect, useState } from 'react';
import Firebase from './features/firebase';
import {instance} from "./features/applications/use-fetch-data";

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const token = localStorage.getItem('kc_token');
  const refreshToken = localStorage.getItem('kc_refreshToken');
  const init = async () => {
    const check = await keycloak.init({
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      onLoad: 'check-sso',
      token,
      refreshToken
    })
    if (check) {
      localStorage.setItem('kc_token', keycloak.token);
      localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
    }
    setAuthenticated(check)
    setInitialized(true)
  }
  const [fbToken, setFbToken] = useState();

  useEffect( () => {
    if (!initialized) {
      init()
    }
  }, [initialized]);

  useEffect(() => {
    if (keycloak.authenticated && fbToken) {
      //Если  авторизоване в клоаке и есть токен фаербейза
      //запросим все инфо о зере из клоаки
      keycloak.loadUserProfile().then(userInfo => {
        //Если получили инфо, отправим токен и email и телефон к нам на бек, чтобы сохранить этот токен с привязкой к юзеру
        instance.post(`/user/firebase-token`,
          {
            token: fbToken,
            email: userInfo.email,
            //если у пользователя задан хотя бы один телефон в клоаке, то он будет придет в attributes.phoneNumber = []
            ...(userInfo?.attributes?.phoneNumber && { phone: userInfo?.attributes?.phoneNumber })
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': `Bearer ${keycloak.token}`
            }
          }).then(e => {
          localStorage.setItem("fb-token", fbToken)
        })
      })
    }
  }, [keycloak?.authenticated, fbToken])

  if(!initialized) {
    return <Loader/>
  }
  if (initialized && !authenticated) {
    return <Layout keycloak={keycloak}>
      <Login keycloak={keycloak}/>
    </Layout>
  }

  return <Firebase setToken={setFbToken}>
    <Layout keycloak={keycloak}>
      <Applications/>
    </Layout>
  </Firebase>
}

export default App;
