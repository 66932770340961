import axios from "axios"
import profileIcon from "../../media/account_circle-32x32.svg"
import styles from "./profile-control.module.scss"
import Button from "../button"
import { useRef, useState } from "react"
import keycloak from "../../utils/Keycloak";
import cn from "classnames"
import { useOnClickOutside } from "../../utils/ui-functions"
import LogoutConfirm from "./logout-confirm";
import { VERSION } from '../../utils/constants'

export default function () {
  const [opened, setOpened] = useState(false);

  const logoutAction = async () => {
    await axios.post(`${process.env.REACT_APP_KC_HOST}/realms/${process.env.REACT_APP_KC_REALM}/protocol/openid-connect/logout`, {
      "client_id": "test-cli",
      "refresh_token": keycloak.refresh_token
    }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${keycloak.access_token}`
      }
    })
  }
  const ref = useRef()
  const [showModalConfirm, setShowModalConfirm] = useState()
  useOnClickOutside(ref, () =>  setOpened(false))

  return <div className={styles.container}>
    <img src={profileIcon} alt="" className={styles.icon} onClick={() => setOpened(!opened)}/>
    <div className={cn(styles.menu, {[styles.opened]: opened && !showModalConfirm})} ref={ref}>
      <div className={styles.username}>
        {keycloak.tokenParsed.given_name}
        &nbsp;
        {keycloak.tokenParsed.family_name}
      </div>
      <Button
          onClick={e => {
            e.stopPropagation();
            setShowModalConfirm(true)
          }}
          className={styles.button}
      >
        Выйти
      </Button>
      <LogoutConfirm keycloak={keycloak} setShowModalConfirm={setShowModalConfirm} showModalConfirm={showModalConfirm}/>
      <div className={styles.version}>Версия: {VERSION}.{process.env.REACT_APP_VERSION}</div>
    </div>
  </div>
}