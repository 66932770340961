import classNames from "classnames";
import styles from "./application-filters.module.scss";
import closeIcon from "../../media/x-symbol-svgrepo-com.svg"

export default function({groups, setFilter = () => {}, filters = []}) {

  return <div className={styles.wrapper}>
    <div className={styles.container}>
      {groups?.map(group => <div className={classNames(styles.item, {[styles.active]: filters.includes(group.id)})} onClick={() => setFilter(group.id)} key={`group-${group.id}`}>{group.title}</div>)}
      <div className={styles.reset}>
        <div className={styles.item} title={"Сбросить все"} onClick={() => setFilter([])}>
          <img src={closeIcon}/>
        </div>
      </div>
    </div>
  </div>
}