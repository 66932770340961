import styles from "./layout.module.scss";
import ProfileControl from "../profile-control";
import cn from "classnames";
import logoWhite from "../..//media/logo-100x22-white.svg";
import logoViolet from "../..//media/logo-100x22-violet.svg";


export default function Layout ({children, keycloak}) {

  return <div className={cn(styles.container, {[styles.authorized]: keycloak.authenticated})}>
    <header className={cn(styles.header, {"container": keycloak.authenticated})}>
      <img src={keycloak.authenticated ? logoViolet : logoWhite} alt="" />
      {
        keycloak.authenticated && <ProfileControl keycloak={keycloak}/>
      }
    </header>
    <div className={cn(styles.content, {"container": keycloak.authenticated})}>
        {children}
    </div>
  </div>
} 