import Modal from "../modal"
import keycloak from "../../utils/Keycloak";
import confirmStyles from "./logout-confirm.module.scss"
import {instance} from "../../features/applications/use-fetch-data";

export default function LogoutConfirm ({showModalConfirm, setShowModalConfirm}) {

    return <>
        <Modal
            showModal={showModalConfirm}
            setShowModal={setShowModalConfirm}
            callback={async () => {
                const token = localStorage.getItem("fb-token");

                if(token){
                    await instance.delete(
                        `/user/firebase-token/${token}`,
                            {
                            headers: {
                                'Authorization': `Bearer ${keycloak.token}`
                            }
                        })
                    await localStorage.removeItem("fb-token")
                }

                localStorage.removeItem('kc_token');
                localStorage.removeItem('kc_refreshToken');
                keycloak.logout({
                  redirectUri: false
                })
            }}
            okText={"Да"}
        >
            <div className={confirmStyles.title}>Вы уверены, что хотите выйти?</div>
        </Modal>
    </>
}