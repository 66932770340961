import {  useMemo, useState } from "react"
import styles from "./applications.module.scss"
import Loader from "../../components/loader"
import ApplicationItem from "../../components/application-item/application-item";
import classNames from "classnames";
import Filters from "../../components/group-filters";
import Search from "../../components/application-search";
import NoApplicationsFound from "../../components/no-applications-found";
import useFetchApps from "./use-fetch-data";


export default function Applications () {
  const {data: groups, loading} = useFetchApps();
  const isMobileView = window.innerWidth < 1024
  const checkLength = isMobileView ? 2 : 3
  const [filters, setFilters] = useState([]);
  const filtersHander = id => {
    if(Array.isArray(id)) {
      setFilters(id)
    } else {
      if (filters.includes(id)) {
        setFilters(filters.filter(f => f !== id))
      } else {
        setFilters([...filters, id])
      }
    }
  }
  const [search, setSearch] = useState("");
  const groupsFiltred = useMemo(() => {
    const prepareValue = string => string.toLowerCase().replaceAll(" ", "")

    return groups
      ?.map(group => ({
        ...group,
        applications: group.applications.filter(app => prepareValue(app.title).includes(prepareValue(search)))
      }))
      .filter(g => (filters.includes(g.id) || filters.length === 0) && g.applications.length)
  }, [filters, groups, search]);

  return <div className={styles.container}>
    {
      groups && groups.length > 0 && <Search setSearch={setSearch} search={search}/>
    }
    {
      groups && groups.length > 0 && <Filters groups={groups} setFilter={filtersHander} filters={filters}/>
    }
    {
      loading ? 
      <Loader/> :
       groupsFiltred?.map(group => <div key={`grpup-${group.id}`}>
        <div className={classNames(styles.group__title, "container_16px")}>{group.title}</div>
        <div className={styles.group__applications}>
          <div className={classNames(styles.group__applicationsWrapper, {[styles.poor]: group.applications?.length < checkLength})}>
            {group.applications?.sort((app1, app2) => {
              if (app1.sort === null) return 1;
              if (app2.sort === null) return -1;
              if (app1.sort === null && app2.sort === null) return 0;
              if (app1.sort === app2.sort) return app1.title.localeCompare(app2.title);
              return app1.sort - app2.sort;
            }).map(app => <ApplicationItem app={app} key={`app-${app.id}`} isPoor={group.applications.length < checkLength}/>)}
          </div>
        </div>
      </div>)
    }
    {
      groups?.length === 0 || groupsFiltred?.length === 0 && <NoApplicationsFound/>
    }
  </div>
}